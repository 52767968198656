<template>
	<button
    @click="showModal"
    type="button"
    :class="options_button"
    :style="{
        background: `linear-gradient(to right, ${fromColor}, ${toColor})`,
        color: 'white',
        padding: '0.5rem 1rem',
        borderRadius: '0.25rem'
    }">
		{{ buttonText }}
    </button>
	<FwbModal size="md" v-if="isShowModal" @close="closeModal">
		<template #header>
			<div class="flex items-center text-lg text-gray-500 dark:text-gray-400">
				{{ buttonText }}
			</div>
		</template>
		<template #body>
			<p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
				{{ modalText }}
			</p>
		</template>
		<template #footer>
			<div class="flex justify-between">
				<button @click="closeModal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
					Cancel
				</button>
				<button @click="onSubmit(); closeModal();" type="button" :class="options_modal" class="h-full"
                :style="{
                    background: `linear-gradient(to right, ${fromColor}, ${toColor})`,
                    color: 'white',
                    borderRadius: '0.25rem'
                }">
					{{ buttonText }}
				</button>
			</div>
		</template>
	</FwbModal>
</template>

<script setup>
import { FwbModal } from 'flowbite-vue'
import { ref } from 'vue'

const isShowModal = ref(false)
function closeModal() {
	isShowModal.value = false
}
function showModal() {
	isShowModal.value = true
}

const props = defineProps({
	options_button: {
		type: String,
		default: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center'
	},
	options_modal: {
		type: String,
		default: 'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center'
	},
	buttonText: {
		type: String,
		default: "Decline",
	},
	modalText: {
		type: String,
		default: "Are you sure you wan't to decline for this event?",
	},
    fromColor: {
        type: String,
        default: "#f87171"
    },
    toColor: {
        type: String,
        default: "#dc2626"
    },
	onSubmit: {
    type: Function,
    required: true,
  },
});

</script>
