<template>
    <div
      :class="[ 'mb-4', error ? 'pl-2 border-red-500 border-l' : '' ]"
      ref="inputWrapper"
    >
      <label :for="id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {{ label }}
      </label>
      <p v-if="explanation_first" id="helper-text-explanation" class="mb-2 text-sm text-gray-500 dark:text-gray-400">
        {{ explanation_first }}
      </p>
      <select v-model="model" :id="props.id"
      class="block w-full p-2.5 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        <option v-for="index in max_tickets" :value="index" :key="index">{{ index }}</option>
      </select>
      <p v-if="explanation_second" id="helper-text-explanation" class="mb-2 text-sm text-gray-500 dark:text-gray-400">
        {{ explanation_second }}
      </p>
      <p v-if="error" class="text-red-500 text-sm">{{ error }}</p>
    </div>
  </template>
  
  <script setup>
  import { ref, watch } from 'vue';
  
  const props = defineProps({
    label: String,
    id: String,
    max_tickets: Number,
    placeholder: {
      type: String,
      default: '',
    },
    error: String,
    explanation_first: {
      type: String,
      default: '',
    },
    explanation_second: {
      type: String,
      default: '',
    },
  });

  const model = defineModel();
  const inputField = ref(null);
  const inputWrapper = ref(null);
  
  watch(() => props.error, (newError) => {
    if (newError) {
      inputWrapper.value?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      inputField.value?.focus();
    }
  });
  </script>
  