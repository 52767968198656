<template>
    <div class="w-screen h-full flex flex-col justify-center items-center text-white">
        <div id="video-background-wrapper" class="video-background-wrapper">
            <div class="fullscreen-video-wrapper">
                <div class="overlay-loader"></div>
                <div class="vimeo-wrapper">
                    <video :src="video_src" autoplay loop muted playsinline>
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
        <div class="z-50 flex flex-col items-center justify-center px-6 py-8 mx-auto md:min-h-screen pt:mt-0">
            <a v-bind:href="'signed24'" class="flex items-center mb-6 text-2xl font-semibold text-white">
                <img class="h-12 mr-2" :src="event_logo" alt="Logo">
            </a>
            <div>
                <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                    <div class="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                        <h2
                            class="text-xl font-bold leading-tight tracking-tight text-center text-gray-900 md:text-2xl dark:text-white">
                            {{ formLabels.heading }}
                        </h2>
                        <p
                            class="text-sm font-medium leading-tight tracking-tight text-center text-gray-700 md:text-md dark:text-gray-300">
                            {{ formLabels.subheading }}</p>
                        <div class="flex !mt-6">
                            <a type="button" href="https://livecrowd.com/betterman/" target="_blank"
                                class="w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded text-sm py-2.5 text-center">Any
                                questions? Contact Livecrowd</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>


import FormInput from './FormInput.vue';
import FormTicketInput from './FormTicketInput.vue';
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { initFlowbite } from 'flowbite'
import { FwbAlert } from 'flowbite-vue'
import { useHead } from '@vueuse/head'
import { z } from 'zod';

const isCheckboxChecked = ref(false);
const formResponse = ref("");
const baseUrl = ref(import.meta.env.VITE_BASE_URL);

const event_logo = "https://cdn.crafture.com/guestticket/logos/CRAFTURE_SEARCHERS_BETTERMAN_LOGO.png"
const video_src = "https://cdn.crafture.com/guestticket/bg%20video/CRAFTURE_SEARCHERS_BETTERMAN_BG_VIDEO_v1.01.mp4"
const favicon = "https://guestticket.crafture.com/media/client_logos/Crafture/CRAFTURE_LOGO_BEELDMERK_wit_op_zwart.jpg"
const version_pk = ref(Number(import.meta.env.VITE_VERSION_PK_SIGNUP))


const title = ref("Better Man | Amex")

useHead({
    title: title,
    link: [
        {
            rel: 'icon',
            type: 'image/png',
            href: favicon
        }
    ]
})

onMounted(() => {
    initFlowbite();
})

const formLabels = ref({
    heading: "Register for tickets Benelux première BETTER MAN",
    subheading: "Sorry, there are no more tickets available.",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone number",
    phone_sub_text: "We send all updates about the event and your tickets via WhatsApp. This way, we can assist you quickly, and you'll always have your tickets at hand. Please enter your mobile phone number below:",
    phone_under_sub_text: "No worries, your number will only be used to assist you and send your tickets.",
    email: "E-mail",
    email_sub_text: "If we are unable to reach you via WhatsApp, please leave your email address here",
    tickets: "Number of people",
    legal_text_one: "I agree to the ",
    legal_text_two: "terms and conditions",
    legal_link: "https://livecrowd.com/privacy",
    submit_text: "Register",
    livecrowd_help_text: "Questions about your registration? Please contact Livecrowd.",
    livecrowd_help_link: "https://livecrowd.com/betterman/",
    registered_text: "Thank you for registering. You will receive a payment link from Livecrowd shortly"
})


const formState = ref({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    number_of_tickets: 1,
    number_of_parking_tickets: 0,
    comments: "",
});

const validationErrors = ref({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    number_of_tickets: '',
    number_of_parking_tickets: ''
});

const formSchema = z.object({
    first_name: z.string().min(2, { message: 'First name is required and must be at least 2 characters long' }),
    last_name: z.string().min(2, { message: 'Last name is required and must be at least 2 characters long' }),
    email: z.string().email({ message: 'Invalid email address' }),
    phone: z.string().refine(
        value => /^\+\d{10,15}$/.test(value),
        { message: 'Phone number must be in a valid international format, such as +31688888888' }
    ),
    number_of_tickets: z.number().min(1, { message: 'Number of tickets must be at least 1' }),
    comments: z.string().max(512, { message: 'Comments must not exceed 512 characters' }).optional()
});

onBeforeUnmount(() => {
    document.body.style = '';
});

// Function to clear validation errors
const clearValidationErrors = () => {
    validationErrors.value = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        number_of_tickets: '',
        number_of_parking_tickets: ''
    };
};

const formRequest = async () => {
    try {
        clearValidationErrors();
        const result = formSchema.safeParse({
            ...formState.value,
        });

        if (!result.success) {
            result.error.issues.forEach(issue => {
                validationErrors.value[issue.path[0]] = issue.message;
            });
            return;
        }

        const formData = new FormData();
        formData.append('first_name', formState.value.first_name);
        formData.append('last_name', formState.value.last_name);
        formData.append('email', formState.value.email);
        formData.append('phone', formState.value.phone);
        formData.append('number_of_tickets', formState.value.number_of_tickets);
        formData.append('number_of_parking_tickets', formState.value.number_of_parking_tickets);
        formData.append('version', version_pk.value);
        formData.append('comments', "AMEX");

        const response = await fetch(
            `${baseUrl.value}/api/applicant/create/`,
            {
                method: 'POST',
                body: formData,
                mode: 'cors',
            },
        );
        const data = await response.json();
        formResponse.value = data;
    } catch (error) {
        console.error("Form submission failed:", error);
        return error;
    }
};

</script>
