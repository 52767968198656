<template>
  <div
    class="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center dark:bg-black"
  >
    <h1 class="text-6xl md:text-[100px] font-bold text-gray-900 dark:text-white">404</h1>
    <p class="text-2xl md:text-4xl mt-4 font-semibold dark:text-gray-100">Pagina niet gevonden</p>
    <p class="mt-2 text-gray-600 text-xl font-medium dark:text-gray-100">
        De pagina die je zoekt, is helaas niet beschikbaar.
    </p>
    <div class="flex !mt-6">
      <a
        type="button"
        href="https://livecrowd.com/support/"
        target="_blank"
        class="text-xl  w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded text-sm py-2.5 px-2 text-center"
        >Neem contact op met Livecrowd</a
      >
    </div>
  </div>
</template>

<script setup></script>
