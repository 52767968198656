<template>
  <div
    :class="[ 'mb-4', error ? 'pl-2 border-red-500 border-l' : '' ]"
    ref="inputWrapper"
  >
    <label :for="id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
      {{ label }}
    </label>
    <p v-if="explanation_first" id="helper-text-explanation" class="mb-2 text-sm text-gray-500 dark:text-gray-400">
      {{ explanation_first }}
    </p>
    <input
      :type="type"
      :id="id"
      :placeholder="placeholder"
      v-model="model"
      :class="[
        'bg-gray-50 border text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:text-white',
        error ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
      ]"
      ref="inputField"
    />
    <p v-if="explanation_second" id="helper-text-explanation" class="mb-2 text-sm text-gray-500 dark:text-gray-400">
      {{ explanation_second }}
    </p>
    <p v-if="error" class="text-red-500 text-sm">{{ error }}</p>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  label: String,
  type: {
    type: String,
    default: 'text',
  },
  id: String,
  placeholder: {
    type: String,
    default: '',
  },
  error: String,
  explanation_first: {
    type: String,
    default: '',
  },
  explanation_second: {
    type: String,
    default: '',
  },
});

const model = defineModel();
const inputField = ref(null);
const inputWrapper = ref(null);

watch(() => props.error, (newError) => {
  if (newError) {
    inputWrapper.value?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    inputField.value?.focus();
  }
});
</script>
