<script>
import RegisterForm from './components/RegisterForm.vue'

export default {
  components: {
    RegisterForm
  }
}
</script>

<template>
    <router-view/>
</template>
  